const ErrorPage = props => {
    
    const { code, children } = props

    return (
        <div className="error-page">
            <div className="item">
                <div className="text">
                    <h1 className="error-page-code">{ code }</h1>
                    { children }
                </div>                
            </div>
        </div>
    );
}

export default ErrorPage