import { useEffect, useState } from 'react'

import { SelectPicker } from 'rsuite'

import { getCodeList } from '../../apis/dms'

const initData = []

const CodeSelect = props => {

    const { codeKey, ...rest } = props
    const [ data, setData ] = useState(initData)

    useEffect(() => {
        getCodeList(codeKey).then(codeList => setData(codeList.map(code => ({ label: code.codeDetailName, value: code.codeDetailKey }))));
    }, [ codeKey ]);

    return (
        <SelectPicker
            data={ data }
            { ...rest }
        ></SelectPicker>
    );
}

export default CodeSelect