import { IconButton } from 'rsuite'
import ArrowLeftLine from '@rsuite/icons/ArrowLeftLine'

import ErrorPage from '../../../components/ErrorPage'

const Error500 = () => {

    return (
        <ErrorPage code={500}>
            <p className="error-page-subtitle text-muted ">您访问的路径或资源出现了错误，请联系管理员</p>
            <IconButton
                icon={<ArrowLeftLine />}
                appearance="primary"
                href={ process.env.REACT_APP_REQUEST_INDEX }
            >
                返回首页
            </IconButton>
        </ErrorPage>
    );
}

export default Error500