import {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Schema,
    Button,
    Drawer,
    Form,
    Divider,
} from 'rsuite';

import CodeView from '../../../components/CodeView'
import {
    addDetail,
    updateDetail,
    getDetail,
    formatExtent,
    parseExtent,
} from '../../../apis/system/code';

const initFormData = {
    codeDetailId: '',
    codeDetailKey: '',
    codeDetailName: '',
    color: '',
    remark: '',
    state: '',
}

const validatorModel = Schema.Model({
    codeDetailKey: Schema.Types.StringType()
        .isRequired('代码明细key不能为空')
        .pattern(/^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$/, '代码明细key格式有误'),
    codeDetailName: Schema.Types.StringType()
        .isRequired('代码明细名称不能为空'),
});

const [ ADD, UPDATE ] = [ 'ADD', 'UPDATE' ]

const CodeDetailInfo = props => {

    const [ mode, setMode ] = useState(ADD)
    const { show, infoId, onClose, onFinish } = props

    const [ formData, setFormData ] = useState(initFormData)

    const resetForm = () => setFormData(initFormData)
    const finish = () => {
        resetForm()
        onFinish?.()
    }
    const handleSubmit = () => {

        if (!formRef.current.check()) {
            return
        }
        formatExtent(formData)
        if (mode === ADD) {
            addDetail(formData).then(finish)
            return
        }
        updateDetail(formData).then(finish)
    }

    const handleChangeFormData = formData => {
        setFormData({ ...formData, codeDetailKey: formData.codeDetailKey.toUpperCase() })
    }

    const formRef = useRef()

    useEffect(() => {
        setMode(infoId ? UPDATE : ADD)
        if (infoId) {
            getDetail(infoId).then(response => {
                const { extent } = response
                setFormData({ ...response, ...parseExtent(extent) })
            });
            return
        }
        resetForm()
    }, [ infoId ]);

    return (
        <Drawer
            size="sm"
            placement="right"
            backdrop="static"
            open={ show }
            onClose={ onClose }
        >
            <Drawer.Header>
                <Drawer.Title>代码明细信息</Drawer.Title>

                <Drawer.Actions>
                    <Button
                        appearance="primary"
                        onClick={ handleSubmit }
                    >
                        确定
                    </Button>

                    <Button
                        appearance="subtle"
                        onClick={ onClose }
                    >
                        取消
                    </Button>                    
                </Drawer.Actions>
            </Drawer.Header>
                
            <Drawer.Body>
                <Form
                    fluid
                    ref={ formRef }
                    model={ validatorModel }
                    formValue={ formData }
                    onChange={ handleChangeFormData }
                >
                    <Form.Group>
                        <Form.ControlLabel>代码明细key</Form.ControlLabel>
                        <Form.Control
                            name="codeDetailKey"
                            maxLength={50}
                            plaintext={ mode === 'UPDATE' }
                        />
                    </Form.Group>
                
                    <Form.Group>
                        <Form.ControlLabel>代码明细名称</Form.ControlLabel>
                        <Form.Control name="codeDetailName" />
                    </Form.Group>
                
                    <Form.Group>
                        <Form.ControlLabel>代码明细说明</Form.ControlLabel>
                        <Form.Control name="remark" />
                    </Form.Group>

                    {
                        mode === UPDATE && (
                            <Form.Group>
                                <Form.ControlLabel>代码明细状态</Form.ControlLabel>
                                <Form.Control
                                    name="state"
                                    accepter={CodeView}
                                    codeKey="STATE"
                                    codeValue={formData.state}
                                />
                            </Form.Group>
                        )
                    }

                <Divider />
                    <Form.Group>
                        <Form.ControlLabel>代码颜色</Form.ControlLabel>
                        <Form.Control type="color" name="color" />
                    </Form.Group>
                </Form>
            </Drawer.Body>
        </Drawer>
    );
}

export default CodeDetailInfo