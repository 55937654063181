export const [
    LIST,
    PAGE,

    ADD,
    UPDATE,
    LOCK,
    UNLOCK,
    REMOVE,
    
    BATCH_ADD,
    BATCH_UPDATE,
    BATCH_LOCK,
    BATCH_UNLOCK,
    BATCH_REMOVE
] = [
    'LIST',
    'PAGE',
    
    'ADD',
    'UPDATE',
    'LOCK',
    'UNLOCK',
    'REMOVE',

    'BATCH_ADD',
    'BATCH_UPDATE',
    'BATCH_LOCK',
    'BATCH_UNLOCK',
    'BATCH_REMOVE'
]