import axios from 'axios'
import networkConfig from './networkConfig'

import { error } from '../components/Message'

import { copyJsonProperties } from './util'
import { getAccessToken, getRefreshToken, removeAccessToken, setAccessToken } from './auth'
import { refreshToken } from './auth/token'

const network = axios.create(networkConfig)

const setAuthorization = (config, accessToken) => {
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken || ''}`
    }
}
const refreshTokenObj = async () => {
    // 刷新token
    const refreshTokenValue = getRefreshToken()
    if (!refreshTokenValue) {
        navigate(process.env.REACT_APP_REQUEST_LOGIN)
        return
    }

    removeAccessToken()
    const refreshTokenResponse = await refreshToken(refreshTokenValue)
    const accessTokenObj = refreshTokenResponse?.data?.data
    setAccessToken(accessTokenObj)
    return accessTokenObj?.accessToken
}

const setDefaultHeader = async config => {
    setAuthorization(config, getAccessToken() || await refreshTokenObj())
    return config
}

const serviceCodes = {}
export function registerServiceCode(codes) {
    copyJsonProperties(codes, serviceCodes)
}

const getServiceMessage = serviceCodeResponse => {
    if (serviceCodeResponse && serviceCodeResponse.length > 0) {
        return serviceCodeResponse.map(obj => {
            const { serviceCode } = obj
            return serviceCodes[serviceCode] || serviceCodes
        }).join(',')
    }
    return ''
}

network.interceptors.request.use(config => {
    loadding?.(true)
    setDefaultHeader(config)
    return config
}, error => {
    loadding?.(false)
    return Promise.reject(error)
});

const emptyFunction = () => {}
network.interceptors.response.use(async response => {
    loadding?.(false)
    const responseData = response.data
    const code = responseData?.code

    if (!code || notLogin(code)) {
        // 未登录
        navigate(process.env.REACT_APP_REQUEST_LOGIN)
    }
    if (isSuccess(code)) {
        // 是否操作成功
        return responseData?.data
    }
    if (notAuthenticated(code)) {
        // 无权限
        navigate(process.env.REACT_APP_REQUEST_403)
    }
    if (isServerInterval(code)) {
        // 系统错误
        navigate(process.env.REACT_APP_REQUEST_500)
    }
    if (isAuthorizationExpire(code)) {
        // 授权已过期，需要刷新token
        const originalRequest = response.config
        const refreshToken = await refreshTokenObj();
        if (refreshToken) {
            setAuthorization(originalRequest, refreshToken)
            return network(originalRequest)
        }
    }
    if (isServiceValidateError(code)) {
        const message = getServiceMessage(responseData.data)
        error(`参数有误${message ? ':' + message : ''}`)
    }
    return new Promise(emptyFunction)
}, e => {
    loadding?.(false)
    error('网络请求错误，原因：' + `${e.message || '未知'}`)
    return Promise.reject(e)
});

// code是否为'成功'
const isSuccess = code => code >= 200 && code <= 299

// code是否为'未登录'
const notLogin = code => code === 510
// code是否为'无权限'
const notAuthenticated = code => code === 513
// code是否为'系统错误'
const isServerInterval = code => code === 500
// code是否为'授权过期'
const isAuthorizationExpire = code => code === 511 || code === 512
// code是否为'业务校验错误'
const isServiceValidateError = code => code === 520 || code === 521

let navigateObject
const navigate = path => navigateObject?.(path)

export function setNavigateObject(navigateObjectValue) {
    navigateObject = navigateObjectValue
}

let loadding
export function setLoaddingHook(loaddingHook) {
    loadding = loaddingHook
}
export default network