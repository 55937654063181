import { IconButton } from 'rsuite'
import ArrowLeftLine from '@rsuite/icons/ArrowLeftLine'

import ErrorPage from '../../../components/ErrorPage'

const Error403 = () => {

    return (
        <ErrorPage code={403}>
            <p className="error-page-subtitle text-muted ">您无权限访问当前路径，请联系管理员授权</p>
            <IconButton
                appearance="primary"
                icon={<ArrowLeftLine />}
                href={ process.env.REACT_APP_REQUEST_INDEX }
            >
                返回首页
            </IconButton>
        </ErrorPage>
    );
}

export default Error403