import {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Schema,
    Button,
    Drawer,
    Form,
} from 'rsuite';

import CodeView from '../../../components/CodeView'
import {
    add,
    update,
    get,
} from '../../../apis/system/code';
import { success } from '../../../components/Message';

const initFormData = {
    codeId: '',
    codeKey: '',
    remark: '',
    state: '',
}

const validatorModel = Schema.Model({
    codeKey: Schema.Types.StringType()
        .isRequired('代码key不能为空')
        .pattern(/^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$/, '代码key格式有误')
});

const [ ADD, UPDATE ] = [ 'ADD', 'UPDATE' ]

const CodeInfo = props => {

    const [ mode, setMode ] = useState(ADD)
    const { show, infoId, onClose, onFinish } = props

    const [ formData, setFormData ] = useState(initFormData)

    const resetForm = () => setFormData(initFormData)
    const finish = () => {
        resetForm()
        onFinish?.()
    }
    const handleSubmit = () => {

        if (!formRef.current.check()) {
            return
        }
        if (mode === ADD) {
            add(formData).then(() => {
                success('新增成功')
                finish()
            });
            return
        }
        update(formData).then(() => {
            success('更新成功')
            finish()
        });
    }

    const handleChangeFormData = formData => {
        setFormData({ ...formData, codeKey: formData.codeKey.toUpperCase() })
    }

    const formRef = useRef()

    useEffect(() => {
        setMode(infoId ? UPDATE : ADD)
        if (infoId) {
            get(infoId).then(setFormData)
            return
        }
        resetForm()
    }, [ infoId ]);

    return (
        <Drawer
            size="sm"
            placement="right"
            backdrop="static"
            open={ show }
            onClose={ onClose }
        >
            <Drawer.Header>
                <Drawer.Title>代码信息</Drawer.Title>

                <Drawer.Actions>
                    <Button
                        appearance="primary"
                        onClick={ handleSubmit }
                    >
                        确定
                    </Button>

                    <Button
                        appearance="subtle"
                        onClick={ onClose }
                    >
                        取消
                    </Button>                    
                </Drawer.Actions>
            </Drawer.Header>
                
            <Drawer.Body>
                <Form
                    fluid
                    ref={ formRef }
                    model={ validatorModel }
                    formValue={ formData }
                    onChange={ handleChangeFormData }
                >
                    <Form.Group>
                        <Form.ControlLabel>代码key</Form.ControlLabel>
                        <Form.Control
                            name="codeKey"
                            maxLength={50}
                            plaintext={ mode === 'UPDATE' }
                        />
                    </Form.Group>
                
                    <Form.Group>
                        <Form.ControlLabel>代码说明</Form.ControlLabel>
                        <Form.Control name="remark" />
                    </Form.Group>

                    {
                        mode === UPDATE && (
                            <Form.Group>
                                <Form.ControlLabel>代码状态</Form.ControlLabel>
                                <Form.Control
                                    name="state"
                                    accepter={CodeView}
                                    codeKey="STATE"
                                    codeValue={formData.state}
                                />
                            </Form.Group>
                        )
                    }
                </Form>
            </Drawer.Body>
        </Drawer>
    );
}

export default CodeInfo