import { useState } from 'react';
import { Loader } from 'rsuite'

const Loading = props => {

    const { setLoadingHook } = props
    const [ loading, setLoading ] = useState(false)
    setLoadingHook?.(setLoading)
    
    return loading && (
        <Loader
            size='md'
            backdrop
            content="加载中..."
            vertical
            style={{ zIndex: 1999 }}
        />
    );
}

export default Loading