import React, { useEffect } from 'react'
import {
    useNavigate,
    useLocation,
} from 'react-router-dom';

import auth, { removeAccessToken } from '../../../apis/auth'

const AuthProvider = props => {
    const { children } = props

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!location.pathname.endsWith(process.env.REACT_APP_REQUEST_LOGIN) && !auth()) {
            removeAccessToken()
            navigate(process.env.REACT_APP_REQUEST_LOGIN)
        }
        // eslint-disable-next-line
    }, [ location ]);

    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    );
}

export default AuthProvider