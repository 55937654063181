import React, {
    useEffect,
    useState,
} from 'react'

import {
    Table,
    Button,
    DOMHelper,
    Divider,
    Stack,
    Pagination,
} from 'rsuite';
import PlusRoundIcon from '@rsuite/icons/PlusRound'

import CodeView from '../../../components/CodeView'

import CodeInfo from './CodeInfo'
import CodeSearch, { initSearchParameter } from './CodeSearch'

import { LOCK, UNLOCK } from '../../../apis/service'
import {
    page,
    lock,
    unlock,
    remove,
    rowKey,
} from '../../../apis/system/code';
import { confirmWarning } from '../../../components/Message/Message';


const initInfo = { show: false, id: '' }
const initPageParameter = { page: 1, search: initSearchParameter }
const initPageData = { all: 0, page: 1, pageSize: 50, pageData: [] }

const CodeList = props => {

    const { switchMode } = props

    const [ info, setInfo ] = useState(initInfo)
    const [ pageParameter, setPageParameter ] = useState(initPageParameter)
    const [ pageData, setPageData] = useState(initPageData)

    const handleAdd = () => setInfo({ show: true, id: '' })
    const handleSearch = search => setPageParameter({ ...initPageParameter, search })
    const handleInfoClose = () => setInfo(initInfo)
    const handleRefresh = () => setPageParameter({ ...pageParameter })
    const handleInfoFinish = () => {
        setInfo(initInfo)
        handleRefresh()

    }
    const handleChangePage = page => handleSearch({ ...pageParameter, page })

    const handleDetail = data => switchMode({ data })

    const handleUpdateState = (rowData, type) => {
        if (type === LOCK) {
            lock(rowData[rowKey]).then(handleRefresh)
            return
        }
        if (type === UNLOCK) {
            unlock(rowData[rowKey]).then(handleRefresh)
            return
        }
    }

    const handleRemove = rowData => confirmWarning('是否确定删除代码以及所属所有代码明细？', () => remove(rowData[rowKey]).then(handleRefresh))

    useEffect(() => {
        page(pageParameter).then(setPageData)
    }, [ pageParameter ]);

    return (
        <React.Fragment>
            <Stack
                className="grt-panel"
                justifyContent="space-between"            
                spacing={10}
            >
                <Stack
                    className="grt-stack"
                    divider={<Divider vertical />}
                >
                    <Button
                        size="md"
                        color="blue"
                        appearance="ghost"
                        startIcon={<PlusRoundIcon />}
                        onClick={ handleAdd }
                    >
                        新增代码
                    </Button>
                </Stack>
            </Stack>

            <CodeInfo
                show={ info.show }
                infoId={ info.id }
                onClose={ handleInfoClose }
                onFinish={ handleInfoFinish }
            />

            <CodeSearch onSearch={ handleSearch } />

            <Table
                className="grt-table"
                height={ Math.max(DOMHelper.getHeight(window) - 314, 400) }
                rowKey={ rowKey }
                data={ pageData.pageData }
            >
                <Table.Column
                    align="center"
                    fixed
                    width={70}
                >
                    <Table.HeaderCell>序号</Table.HeaderCell>
                    <Table.Cell>
                        {
                            (_, i) => i + 1
                        }
                    </Table.Cell>
                </Table.Column>

                
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>代码key</Table.HeaderCell>
                    <Table.Cell dataKey="codeKey" />
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>代码名称（描述）</Table.HeaderCell>
                    <Table.Cell dataKey="remark" />
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>下级代码明细数量</Table.HeaderCell>
                    <Table.Cell dataKey="detailCount" />
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>状态</Table.HeaderCell>
                    <Table.Cell>
                        {
                            rowData => <CodeView codeKey="STATE" codeValue={rowData.state} />
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={320} align="center">
                    <Table.HeaderCell>操作</Table.HeaderCell>
                    <Table.Cell style={{ padding: '6px' }}>
                        {
                            rowData => (
                                <>
                                    <Button
                                        appearance="link"
                                        onClick={() => setInfo({ show: true, id: rowData[rowKey] })}
                                    >
                                        查看
                                    </Button>
                                    <Button
                                        appearance="link"
                                        onClick={ () => handleDetail(rowData) }
                                    >
                                        查看下级代码明细
                                    </Button>
                                    {
                                        rowData.state === 'OK' ? 
                                        <Button
                                            appearance="link"
                                            color='orange'
                                            onClick={ () => handleUpdateState(rowData, LOCK) }
                                        >
                                            锁定
                                        </Button> : 
                                        <Button
                                            appearance="link"
                                            color='orange'
                                            onClick={ () => handleUpdateState(rowData, UNLOCK) }
                                        >
                                            解锁
                                        </Button>
                                    }                            
                                    <Button
                                        appearance="link"
                                        color='red'
                                        onClick={ () => handleRemove(rowData) }
                                    >
                                        删除
                                    </Button>
                                </>
                            )
                        }
                    </Table.Cell>
                </Table.Column>                
            </Table>

            <div className="grt-page">
                <Pagination
                    size="xs"
                    layout={[ 'total', '-', 'pager', 'skip' ]}
                    prev={true}
                    next={true}
                    first={true}
                    last={true}
                    ellipsis={true}
                    boundaryLinks={true}                    
                    maxButtons={5}
                    total={ pageData.all }
                    limit={ pageData.pageSize }
                    activePage={ pageData.page }
                    onChange={ handleChangePage }
                />
            </div>
        </React.Fragment>
    );
}

export default CodeList