import { useState } from 'react';
import {
    Button,
    Divider,
    Input,
    Stack,
} from 'rsuite';

export const initSearchParameter = { likeCodeKey: '', likeRemark: '' }

const CodeSearch = props => {

    const { onSearch } = props
    const [ searchParameter, setSearchParameter ] = useState(initSearchParameter)

    const handleChangeSearchParameter = (key, value) => {
        setSearchParameter({ ...searchParameter, [key]: value })
    }

    const handleClickSearch = () => {
        onSearch?.(searchParameter)
    }

    return (
        <Stack
            className="grt-panel"
            justifyContent="space-between"
            spacing={10}
        >
            <Stack
                className="grt-stack"
                divider={<Divider vertical />}
            >
                <Stack spacing={10}>
                    代码key：
                    <Input
                        value={ searchParameter.likeCodeKey }
                        onChange={ value => handleChangeSearchParameter('likeCodeKey', value) }
                    />
                </Stack>

                <Stack spacing={10}>
                    代码名称：
                    <Input
                        value={ searchParameter.likeRemark }
                        onChange={ value => handleChangeSearchParameter('likeRemark', value) }
                    />
                </Stack>

                <Stack spacing={10}>
                    <Button
                        appearance="primary"
                        onClick={ handleClickSearch }
                    >
                        搜索
                    </Button>
                </Stack>                
            </Stack>
        </Stack>
    );
}

export default CodeSearch