import {
    Breadcrumb,
    Stack,
} from 'rsuite'

const BreadCrumb = props => {

    const { items = [] } = props

    return (
        <Stack
            className="grt-panel"
            justifyContent="space-between"
            spacing={10}
        >
            <Breadcrumb className="grt-breadcrumb grt-stack">
                <Breadcrumb.Item href="/">首页</Breadcrumb.Item>
                {
                    items.map((item, i) => {
                        const { href, active = false, title } = item
                        return <Breadcrumb.Item key={ i } active={ active } href={ href }>{ title }</Breadcrumb.Item>
                    })
                }
            </Breadcrumb>
        </Stack>
    );
}

export default BreadCrumb