import React from 'react'
import { NavLink } from 'react-router-dom'

import { Nav } from 'rsuite'

const NavItemLink = React.forwardRef(({ children, ...rest }, ref) => {
    return (
        <NavLink
            ref={ ref }
            { ...rest }
        >
            { children }
        </NavLink>
    );
});

const NavItem = props => {

    const { title, ...rest } = props
    return (
        <Nav.Item
            as={ NavItemLink }
            { ...rest }
        >
            { title }
        </Nav.Item>
    );
}

export default NavItem