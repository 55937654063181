import {
  Message,
  Notification,
  ButtonToolbar,
  Button,
} from 'rsuite'

const [ INFO, SUCCESS, WARNING, ERROR ] = [ 'info', 'success', 'warning', 'error' ]

let toaster
const setMessageHook = messageHook => toaster = messageHook
const message = (msg, type, config) =>  toaster?.push((
  <Message showIcon type={type} closable>
    { msg }
  </Message>
), config);

const confirmTitle = {
  [INFO]: '信息',
  [SUCCESS]: '成功',
  [WARNING]: '警告',
  [ERROR]: '错误',
}
const confirmMessage = (msg, type, okCallback = () => {}, cancelCallback = () => {}, config) => toaster?.push((
  <Notification type={ type } header={`${confirmTitle[type]}!`} >
    <p>{ msg }</p>
    <hr />
    <ButtonToolbar>
      <Button
        appearance="primary"
        onClick={ () => okCallback?.() !== false && toaster.remove() }
      >
        确定
      </Button>
      <Button
        appearance="default"
        onClick={ () => cancelCallback?.() !== false && toaster.remove() }
      >
        取消
      </Button>
    </ButtonToolbar>
  </Notification>
), config);

const messageConfig = { placement: 'topCenter', duration: 5000 }
const info = msg => message(msg, INFO, messageConfig)
const success = msg => message(msg, SUCCESS, messageConfig)
const warning = msg => message(msg, WARNING, messageConfig)
const error = msg => message(msg, ERROR, messageConfig)

const confirmMessageConfig = { placement: 'topCenter', duration: 5000 }
const confirmInfo = (msg, okCallback, cancelCallback) => confirmMessage(msg, INFO, okCallback, cancelCallback, confirmMessageConfig)
const confirmSuccess = (msg, okCallback, cancelCallback) => confirmMessage(msg, SUCCESS, okCallback, cancelCallback, confirmMessageConfig)
const confirmWarning = (msg, okCallback, cancelCallback) => confirmMessage(msg, WARNING, okCallback, cancelCallback, confirmMessageConfig)
const confirmError = (msg, okCallback, cancelCallback) => confirmMessage(msg, ERROR, okCallback, cancelCallback, confirmMessageConfig)

export {
    info,
    success,
    warning,
    error,
    confirmInfo,
    confirmSuccess,
    confirmWarning,
    confirmError,
    setMessageHook,
}

export default message