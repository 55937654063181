import { useEffect, useState } from 'react'
import { getCode } from '../../apis/dms'

const initCode = { color: '', value: '' }
const CodeView = props => {

    const { codeKey, codeValue = '' } = props
    const [ code, setCode ] = useState(initCode)

    useEffect(() => {
        if (codeKey && codeValue) {
            getCode(codeKey, codeValue).then(codeData => {
                if (codeData) {
                    setCode(codeData)
                }
            });
        }
    }, [ codeKey, codeValue ]);

    return (
        <p style={{ color: code.color }}>{ code.codeDetailName || codeValue }</p>
    );
}

export default CodeView