import React from 'react'
import { IntlProvider } from 'react-intl'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { CustomProvider, useToaster } from 'rsuite'

import { zhCN } from 'rsuite/esm/locales'

import { setNavigateObject, setLoaddingHook } from './apis/network'

import Frame from './components/Frame'
import Dashboard from './components/Dashboard'
import Loading from './components/Loading'

import AuthProvider from './components/Auth/AuthProvider'

import Error403 from './pages/auth/403'
import Error404 from './pages/auth/404'
import Error500 from './pages/auth/500'

import Login from './pages/auth/Login'

import Code from './pages/system/Code'
import Menu from './pages/system/Menu'
import Role from './pages/system/Role'
import Depart from './pages/system/Depart'
import User from './pages/system/User'

import ApiTest from './pages/apiTest'

import routes from './routes'
import { setMessageHook } from './components/Message'

const App = () => {

    setMessageHook(useToaster())
    setNavigateObject(useNavigate())
    
    return (
        <React.Fragment>
            <IntlProvider locale="zh">
                <CustomProvider locale={zhCN}>
                    <Routes>
                        <Route path={process.env.REACT_APP_REQUEST_LOGIN} element={<Login />} />
                            <Route path="/" element={<Frame menus={routes} />} >
                                <Route path="/dashboard" index element={<Dashboard />} />
                                
                                <Route path="/system/code" element={<Code />} />
                                <Route path="/system/menu" element={<Menu />} />
                                <Route path="/system/role" element={<Role />} />
                                <Route path="/system/depart" element={<Depart />} />
                                <Route path="/system/user" element={<User />} />

                                <Route path="/apiTest" element={<ApiTest />} />

                                <Route path={process.env.REACT_APP_REQUEST_403} element={<Error403 />} />
                                <Route path={process.env.REACT_APP_REQUEST_404} element={<Error404 />} />
                                <Route path={process.env.REACT_APP_REQUEST_500} element={<Error500 />} />
                            </Route>
                    </Routes>
                    <AuthProvider />
                </CustomProvider>
            </IntlProvider>
            <Loading setLoadingHook={setLoaddingHook} />
        </React.Fragment>
    );
}

export default App