const store = require('store')

const KEY_ACCESSTOKEN = 'access-token'
const getFromStore = () => store.get(KEY_ACCESSTOKEN)
const setToStore = accessToken => store.set(KEY_ACCESSTOKEN, accessToken)
const removeFromStore = () => store.remove(KEY_ACCESSTOKEN)

export function getAccessToken() {
    return getFromStore()?.accessToken
}

export function getRefreshToken() {
    return getFromStore()?.refreshToken
}

export function setAccessToken(accessToken) {
    const expireDate = new Date()
    expireDate.setSeconds(expireDate.getSeconds() + accessToken.expire)
    setToStore({ ...accessToken, expireDate: expireDate.getTime() })
}

export function removeAccessToken() {
    removeFromStore()
}

export function isAuthenticated() {
    return new Date(getFromStore()?.expireDate || 0) > new Date()
}