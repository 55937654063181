import { useState } from 'react'

import {
    Panel,
} from 'rsuite';

import BreadCrumb from '../../../components/BreadCrumb'

import CodeList from './CodeList'
import CodeDetailList from './CodeDetailList'

import { CODE } from '../../../apis/system/code';

const initBreadCrumbItems = [{ active: true, title: '代码管理' }]
const initModeData = {}

const Code = () => {

    const [ breadCrumbItems, setBreadCrumbItems ] = useState(initBreadCrumbItems)
    const [ mode, setMode ] = useState(CODE)
    const [ modeData, setModeData ] = useState(initModeData)

    const setCurrentModeData = currentModeData => {
        const { mode, data = {} } = currentModeData

        if (mode !== CODE) {
            setBreadCrumbItems([
                { active: false, title: '代码管理', href: '/system/code' },
                { active: true, title: data.codeKey }
            ]);
        }
        setMode(mode)
        setModeData(data)
    }

    return (
        <Panel header={<p className="title">代码管理</p>}>
            <BreadCrumb items={ breadCrumbItems } />
            {
                mode === 'CODE' ?
                    <CodeList switchMode={ setCurrentModeData } /> :
                    <CodeDetailList modeData={ modeData } switchMode={ setCurrentModeData } />
            }
        </Panel>
    );
}

export default Code