import { useRef } from 'react'

import {
    Avatar,
    Badge,
    IconButton,
    Stack,
    Whisper,
} from 'rsuite';
import NoticeIcon from '@rsuite/icons/Notice'

const Header = () => {

    const noticeSpeakerRef = useRef(null)
    const settingSpeakerRef = useRef(null)

    const noticeSpeakerHandler = () => {}
    const settingSpeakerHandler = () => {}

    return (
        <Stack
            className="header"
            spacing={8}
        >
            <Whisper
                placement="bottomEnd"
                trigger="click"
                ref={ noticeSpeakerRef }
                speaker={ noticeSpeakerHandler }
            >
                <IconButton icon={
                    <Badge content={5}>
                        <NoticeIcon style={{ fontSize: 20 }} />
                    </Badge>
                }
                />
            </Whisper>

            <Whisper
                placement="bottomEnd"
                trigger="click"
                ref={ settingSpeakerRef }
                speaker={ settingSpeakerHandler }
            >
                <Avatar
                    size="sm"
                    circle
                    src="https://avatars.githubusercontent.com/u/1203827"
                    alt="@simonguo"
                    style={{ marginLeft: 8 }}
                />
            </Whisper>
        </Stack>
    );
}

export default Header