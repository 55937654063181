import { copyJsonProperties } from './util'
import { listDetailByKey, parseExtent } from './system/code'

const store = require('store')

const KEY_DMS = 'dms'
const getFromStore = () => store.get(KEY_DMS)
const setToStore = dms => store.set(KEY_DMS, dms)
const removeFromStore = () => store.remove(KEY_DMS)

const dms = {}
const copyFromStore = () => {
    const dmsFromStore = getFromStore()
    if (dmsFromStore) {
        copyJsonProperties(dmsFromStore, dms)
    }
    dms.copied = true
}

export async function getCodeList(key) {
    if (!dms.copied) {
        copyFromStore(dms)
    }
    if (!dms[key]) {
        const details = await listDetailByKey(key)
        copyJsonProperties({ [key]: details.map(detail => ({ ...detail, ...parseExtent(detail.extent) })) }, dms)
        setToStore(dms)
    }
    return dms[key]
}

export async function getCode(key ,value) {
    const codeList = await getCodeList(key)
    return codeList.find(item => item.codeDetailKey === value)
}

export function clearCodes() {
    for ( const key in dms ) {
        delete dms[key]
    }
    removeFromStore()
}
