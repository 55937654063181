import network, { registerServiceCode } from '../network'

export const rowKey = 'codeId'
export const detailRowKey = 'codeDetailId'

registerServiceCode({
    '110100': '代码说明超过最大长度',
    '110101': '代码key不能为空',
    '110102': '代码key格式有误',
    '110103': '代码key超过最大长度',
    '110104': '代码key已存在',
    '110105': '代码明细名称不能为空字符',
    '110106': '代码明细名称超过最大长度',
    '110107': '代码明细说明超过最大长度',
    '110108': '代码明细所属代码不能为空',
    '110109': '代码明细所属代码有误',
    '110110': '代码明细所属代码不存在',
    '110111': '代码明细key不能为空',
    '110112': '代码明细key格式有误',
    '110113': '代码明细key已存在'
});

export async function page({ page = 1, search: { likeCodeKey = '', likeRemark = '' } }) {
    return network.get(`/code/page/${page}?likeCodeKey=${likeCodeKey}&likeRemark=${likeRemark}`)
}

export async function get(codeId = '') {
    return network.get(`/code/get/${codeId}`)
}

export async function add({ codeKey, remark }) {
    return network.post('/code/add', { codeKey, remark }, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function update({ codeId, remark }) {
    return network.post('/code/update', { codeId, remark }, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function lock(codeId) {
    return network.post('/code/lock', codeId, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function unlock(codeId) {
    return network.post('/code/unlock', codeId, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function remove(codeId) {
    return network.delete(`/code/remove?id=${codeId}`)
}

export async function listDetail(codeId = '', { likeCodeDetailKey = '', likeCodeDetailName = '', eqState = '' }) {
    return network.get(`/code/listDetail/${codeId}?likeCodeDetailKey=${likeCodeDetailKey}&likeCodeDetailName=${likeCodeDetailName}&eqState=${eqState}`)
}

export async function listDetailByKey(codeKey = '') {
    return network.get(`/code/listDetailByKey/${codeKey}`)
}

export async function getDetail(codeId = '') {
    return network.get(`/code/getDetail/${codeId}`)
}

export async function addDetail({ codeDetailName = '', extent = '', remark = '', codeId = '', codeDetailKey = '' }) {
    return network.post('/code/addDetail', { codeDetailName, extent, remark, codeId, codeDetailKey }, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function updateDetail({ codeDetailId = '', codeDetailName = '', extent = '', remark = '', codeDetailKey = '' }) {
    return network.post('/code/updateDetail', { codeDetailId, codeDetailName, extent, remark, codeDetailKey }, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function batchLockDetail(ids = []) {
    return network.post('/code/batchLockDetail', { ids }, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function batchUnlockDetail(ids = []) {
    return network.post('/code/batchUnlockDetail', { ids }, {
        headers: { "Content-Type": "application/json" }
    });
}

export async function batchRemoveDetail(ids = []) {
    return network.delete(`/code/batchRemoveDetail?${ids.map(id => `ids=${id}`).join('&')}`)
}

const initExtent = { color: '' }
export function parseExtent(extent) {
    if (extent) {
        return { ...initExtent, ...JSON.parse(extent) }
    }
    return { ...initExtent }
}

export function formatExtent(formData) {
    formData.extent = JSON.stringify({ color: formData.color })
}

const [ CODE ] = [ 'CODE' ]
export { CODE }