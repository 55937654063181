import {
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom'

import {
    Form,
    Panel,
    Schema,
    Stack,
    Divider,
    Button,
} from 'rsuite';

import Brand from '../../../components/Brand'

import { setAccessToken } from '../../../apis/auth'
import {
    validate,
    captchaUrl,
    doLogin,
} from '../../../apis/auth/login';

const initFormValue = { username: '', password: '', captcha: '' }

const Login = () => {

    const [ captcha, setCaptcha ] = useState(false)
    const [ formValue, setFormValue ] = useState(initFormValue)
    const [ captchaSrc, setCaptchaSrc ] = useState('')

    const validator = {
        username: Schema.Types.StringType()
            .isRequired('用户名不能为空')
            .pattern(/^[a-zA-Z0-9]{4,}$/, '用户名格式有误'),
        password: Schema.Types.StringType()
            .isRequired('密码不能为空'),
    }
    if (captcha) {
        validator.captcha = Schema.Types.StringType()
            .isRequired('验证码不能为空')
            .pattern(/^[A-Z0-9a-z]{4,6}$/, '验证码格式有误');
    }
    
    const reloadCaptcha = () => {
        setCaptcha(true)
        setCaptchaSrc(captchaUrl())

    }

    const navigate = useNavigate()
    const validateUsername = () => {
        validate(formValue.username).then(response => {
            if (response.requireCaptcha && !formValue.captcha) {
                // 如果需要密码且当前没有输入密码
                reloadCaptcha()
                return 
            }
            doLogin(formValue).then(({ result, accessToken }) => {
                if (result) {
                    setAccessToken(accessToken)
                    navigate(process.env.REACT_APP_REQUEST_INDEX)
                    return
                }
            });
        });
    }

    const handleSubmitForm = () => {
        if (!formRef.current.check()) {
            return
        }
        validateUsername()
    }
    
    const handleClickResetPassword = () => {}
    const handleClickCaptcha = () => reloadCaptcha()

    const validatorModel = Schema.Model(validator)
    const formRef = useRef()

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{
                height: '75vh',
                marginLeft: '50vw',
            }}
        >
            <Brand style={{
                marginBottom: 10,
                fontSize: 30,
            }} />
            <Panel
                bordered
                style={{
                    background: '#fff',
                    width: 450,
                    borderRadius: 15
                }}
                header={<span style={{ fontSize: '20px' }}>请登录</span>}
            >
                <Form
                    fluid
                    model={ validatorModel }
                    ref={ formRef }
                    formValue={ formValue }
                    onChange={ setFormValue }
                >
                    <Form.Group controlId="username">
                        <Form.ControlLabel>用户名</Form.ControlLabel>
                        <Form.Control
                            name="username"
                            maxLength={20}
                        />
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.ControlLabel>密码</Form.ControlLabel>
                        <Form.Control
                            name="password"
                            type="password"
                        />
                    </Form.Group>

                    {
                        captcha && (
                            <Form.Group controlId="captcha">
                                <Form.ControlLabel>验证码</Form.ControlLabel>
                                <Stack spacing={6} divider={<Divider vertical />}>
                                    <Form.Control
                                        name="captcha"
                                        maxLength={6}
                                    />
                                    <div>
                                        <img onClick={handleClickCaptcha} alt="center" width="150px" height="33px" style={{borderRadius: 10, cursor: "pointer"}} src={captchaSrc} />
                                    </div>
                                </Stack>
                            </Form.Group>
                        )
                    }

                    <Form.Group>
                        <Stack spacing={6} divider={<Divider vertical />}>
                            <Button
                                appearance="primary"
                                onClick={handleSubmitForm}
                            >
                                登录
                            </Button>
                            <Stack spacing={6}>
                                <Button appearance="link" onClick={handleClickResetPassword} style={{ float: 'right' }}>忘记密码?</Button>
                            </Stack>
                        </Stack>
                    </Form.Group>
                </Form>
            </Panel>
        </Stack>
    );
}

export default Login