import {
    Panel,
} from 'rsuite';

import BreadCrumb from '../BreadCrumb'

const Dashboard = () => {
    return (
        <Panel header={<p className="title">首页</p>} >
            <BreadCrumb />
        </Panel>
    );
}

export default Dashboard