import React from 'react'

import { Icon, Gear, Code } from '@rsuite/icons'
import { MdDashboard } from 'react-icons/md'

const routes = [
    {
        routeKey: "dashboard",
        icon: <Icon as={MdDashboard} />,
        title: "首页",
        to: "/dashboard"
    },

    {
        routeKey: 'system',
        icon: <Icon as={Gear} />,
        title: '系统管理',
        children: [
            {
                routeKey: 'code',
                title: '代码管理',
                to: '/system/code'
            },
            {
                routeKey: 'menu',
                title: '菜单管理',
                to: '/system/menu'
            },
            {
                routeKey: 'role',
                title: '角色管理',
                to: '/system/role'
            },
            {
                routeKey: 'depart',
                title: '部门管理',
                to: '/system/depart'
            },
            {
                routeKey: 'user',
                title: '用户管理',
                to: '/system/user'
            }
        ]
    },

    {
        routeKey: "apiTest",
        icon: <Icon as={Code} />,
        title: "api测试",
        to: "/apiTest"
    },
]

export default routes;