import React, {
    useEffect,
    useState,
} from 'react'

import {
    Table,
    Button,
    DOMHelper,
    Divider,
    Stack,
    Checkbox,
} from 'rsuite';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine'
import PlusRoundIcon from '@rsuite/icons/PlusRound'
import CheckOutlineIcon from '@rsuite/icons/CheckOutline'
import BlockIcon from '@rsuite/icons/Block'
import TrashIcon from '@rsuite/icons/Trash'

import CodeView from '../../../components/CodeView'

import CodeDetailInfo from './CodeDetailInfo'
import CodeDetailSearch, { initSearchParameter } from './CodeDetailSearch'

import { LOCK, UNLOCK } from '../../../apis/service'
import {
    listDetail,
    batchLockDetail,
    batchUnlockDetail,
    batchRemoveDetail,
    detailRowKey,
    CODE,
} from '../../../apis/system/code';
import { confirmWarning } from '../../../components/Message/Message';

const initInfo = { show: false, id: '' }
const initListData = []
const initAllCheckValue = { checked: false, indeterminated: false }

const CodeDetailList = props => {

    const { modeData, switchMode } = props

    const [ info, setInfo ] = useState(initInfo)
    const [ listParameter, setListParameter ] = useState(initSearchParameter)
    const [ listData, setListData] = useState(initListData)

    const [ allCheckValue, setAllCheckValue ] = useState(initAllCheckValue)
    const [ checkedKeys, setCheckedKeys ] = useState([])    

    const handleAdd = () => setInfo({ show: true, id: '' })
    const handleSearch = search => {
        setListParameter({ ...search })
        handleAllCheck(null, false)
    }
    const handleInfoClose = () => setInfo(initInfo)
    const handleRefresh = () => {
        setListParameter({ ...listParameter })
        handleAllCheck(null, false)
    }
    const handleInfoFinish = () => {
        setInfo(initInfo)
        handleRefresh()
    }

    const handleBack = () => switchMode({ mode: CODE })

    const handleAllCheck = (_, isCheck) => {
        if (isCheck) {
            setAllCheckValue({ checked: true, indeterminated: false })
            setCheckedKeys(listData.map(item => item[detailRowKey]))
            return
        }
        setCheckedKeys([])
        setAllCheckValue({ checked: false, indeterminated: false })
        return
    }

    const handleCheck = (key, isCheck) => {
        const checkedKey = isCheck ? [ ...checkedKeys, key] : checkedKeys.filter(item => item !== key)

        if (checkedKey.length < 1) {
            setAllCheckValue({ checked: false, indeterminated: false })
        } else {
            if (checkedKey.length >= listData.length) {
                setAllCheckValue({ checked: true, indeterminated: false })    
            } else {
                setAllCheckValue({ checked: false, indeterminated: true })
            }
        }
        setCheckedKeys(checkedKey)
    }

    const handleBatchUpdateState = type => {
        if (checkedKeys.length < 1) {
            return
        }
        if (type === LOCK) {
            batchLockDetail(checkedKeys).then(handleRefresh)
            return
        }
        if (type === UNLOCK) {
            batchUnlockDetail(checkedKeys).then(handleRefresh)
            return
        }
    }

    const handleBatchRemove = () => checkedKeys.length > 0 && confirmWarning('是否确定删除选中的代码明细？', () => batchRemoveDetail(checkedKeys).then(handleRefresh))

    useEffect(() => {
        listDetail(modeData.codeId, listParameter).then(setListData)
    }, [ modeData, listParameter ]);

    return (
        <React.Fragment>
            <Stack
                className="grt-panel"
                justifyContent="space-between"            
                spacing={10}
            >
                <Stack
                    className="grt-stack"
                    divider={<Divider vertical />}
                >
                    <Button
                        color="blue"
                        appearance="ghost"
                        size="md"
                        startIcon={<ArrowLeftLineIcon />}
                        onClick={ handleBack }
                    >
                        返回上一级
                    </Button>

                    <Button
                        size="md"
                        color="blue"
                        appearance="ghost"
                        startIcon={<PlusRoundIcon />}
                        onClick={ handleAdd }
                    >
                        新增代码明细
                    </Button>

                    <Button
                        color="orange"
                        appearance="ghost"
                        size="md"
                        startIcon={<BlockIcon />}
                        onClick={ () => handleBatchUpdateState(LOCK) }
                    >
                        锁定代码明细
                    </Button>

                    <Button
                        color="orange"
                        appearance="ghost"
                        size="md"
                        startIcon={<CheckOutlineIcon />}
                        onClick={ () => handleBatchUpdateState(UNLOCK) }
                    >
                        解锁代码明细
                    </Button>
                    
                    <Button
                        color="red"
                        appearance="ghost"
                        size="md"
                        startIcon={<TrashIcon />}
                        onClick={ handleBatchRemove }
                    >
                        删除代码明细
                    </Button>
                </Stack>
            </Stack>

            <CodeDetailInfo
                show={ info.show }
                infoId={ info.id }
                onClose={ handleInfoClose }
                onFinish={ handleInfoFinish }
            />

            <CodeDetailSearch onSearch={ handleSearch } />

            <Table
                className="grt-table"
                height={ Math.max(DOMHelper.getHeight(window) - 314, 400) }
                rowKey={ detailRowKey }
                data={ listData }
            >
                <Table.Column
                    width={50}
                    align="center"
                    fixed
                >
                    <Table.HeaderCell style={{ padding: 0 }} >
                        <div style={{ lineHeight: '46px' }}>
                            <Checkbox
                                inline
                                checked={allCheckValue.checked}
                                indeterminate={allCheckValue.indeterminated}
                                onChange={handleAllCheck}
                            />
                        </div>
                    </Table.HeaderCell>

                    <Table.Cell style={{ padding: 0 }} >
                        {
                            rowData => (
                                <div style={{ lineHeight: '46px' }}>
                                    <Checkbox
                                        inline
                                        value={rowData[detailRowKey]}
                                        checked={checkedKeys.includes(rowData[detailRowKey])}
                                        onChange={handleCheck}
                                    />
                                </div>
                            )
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column
                    align="center"
                    fixed
                    width={70}
                >
                    <Table.HeaderCell>序号</Table.HeaderCell>
                    <Table.Cell>
                        {
                            (_, i) => i + 1
                        }
                    </Table.Cell>
                </Table.Column>

                
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>代码明细key</Table.HeaderCell>
                    <Table.Cell dataKey="codeDetailKey" />
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>代码明细名称</Table.HeaderCell>
                    <Table.Cell dataKey="codeDetailName" />
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>状态</Table.HeaderCell>
                    <Table.Cell>
                        {
                            rowData => <CodeView codeKey="STATE" codeValue={rowData.state} />
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={320} align="center">
                    <Table.HeaderCell>操作</Table.HeaderCell>
                    <Table.Cell style={{ padding: '6px' }}>
                        {
                            rowData => (
                                <React.Fragment>
                                    <Button
                                        appearance="link"
                                        onClick={() => setInfo({ show: true, id: rowData[detailRowKey] })}
                                    >
                                        查看
                                    </Button>
                                </React.Fragment>
                            )
                        }
                    </Table.Cell>
                </Table.Column>                
            </Table>
        </React.Fragment>
    );
}

export default CodeDetailList