import { Link } from 'react-router-dom'

import { Stack } from 'rsuite'

const Brand = props => {

    return (
        <Stack
            className="brand"
            { ...props }
        >
            <Link to="/">
                <span style={{ marginLeft: 14 }}>{ process.env.REACT_APP_TITLE }</span>
            </Link>
        </Stack>
    );
}

export default Brand