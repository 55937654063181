import network from '../network'
import { wrapUrl } from '../networkConfig'

const encodeParameter = ({ password, captcha }) => encodeURIComponent(JSON.stringify([password, captcha]))

export async function validate(username) {
    return network.get(`/login/validate?username=${username}`)
}

export function captchaUrl() {
    return wrapUrl(`/login/captcha?_r=` + Math.random())
}

export async function doLogin(parameter) {
    return network.get(`/login/doLogin?parameter=${encodeParameter(parameter)}`)
}