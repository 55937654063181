import { Outlet } from 'react-router-dom'

import {
    Container,
    Content,
    Nav,
    Sidebar,
    Sidenav,
} from 'rsuite';

import Brand from '../Brand'
import Header from '../Header'
import NavItem from '../NavItem'

const Frame = props => {

    const { menus = [] } = props
    
    return (
        <Container className="frame">
            <Sidebar
                style={{ display: 'flex', flexDirection: 'column' }}
                width={260}
                collapsible
            >
                <Sidenav.Header>
                    <Brand />
                </Sidenav.Header>

                <Sidenav appearance="subtle">
                    <Sidenav.Body>
                        <Nav>
                            {
                                menus.map(menu => {
                                    const { routeKey, to, title, icon, target, children, ...rest } = menu
                                    if (children) {
                                        return (
                                            <Nav.Menu
                                                placement="rightStart"
                                                trigger="hover"
                                                key={ routeKey }
                                                icon={ icon }
                                                title={ title }
                                                { ...rest }
                                            >
                                                {
                                                    children.map((child) => {
                                                        const { routeKey, title, to } = child
                                                        return (
                                                            <NavItem
                                                                key={ routeKey }
                                                                eventKey={ routeKey }
                                                                title={ title }
                                                                to={ to }
                                                            />
                                                        )
                                                    })
                                                }
                                            </Nav.Menu>
                                        );
                                    }

                                    if (target === '_blank') {
                                        return (
                                            <Nav.Item
                                                eventKey={ routeKey }
                                                { ...rest }
                                            >
                                                { title }
                                            </Nav.Item>
                                        )
                                    }

                                    return (
                                        <NavItem
                                            key={ routeKey }
                                            eventKey={ routeKey }
                                            title={ title }
                                            icon={ icon }
                                            to={ to }
                                        />
                                    )
                                })
                            }
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </Sidebar>

            <Container className="page-container">
                <Header />
                <Content>
                    <Outlet />
                </Content>
            </Container>
        </Container>
    );
}

export default Frame