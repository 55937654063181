import React, {
    useRef,
    useState,
} from 'react';

import {
    Form,
    Panel,
    Radio,
    RadioGroup,
    Input,
    Grid,
    Row,
    Col,
    ButtonToolbar,
    Button,
    Schema,
} from 'rsuite';

import BreadCrumb from '../../components/BreadCrumb'
import network from '../../apis/network'

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />)
const initFormValue = {
    method: 'GET',
    url: '',
    bodyType: 'json',
    bodyJson: ''
}

const formValidatorModel = Schema.Model({
    url: Schema.Types.StringType().isRequired('url不能为空')
});

const ApiTest = () => {

    const [ formValue, setFormValue ] = useState(initFormValue)
    const [ response, setResponse ] = useState('')

    const formRef = useRef(null)
    
    const showResponse = response => setResponse(JSON.stringify(response, null, 4))
    const showError = ({ message, code, status }) => setResponse(JSON.stringify({ message, code, status }, null, 4))

    const submit = () => {
        if (!formRef.current.check()) {
            return ;
        }

        const { url, method, bodyJson } = formValue
        setResponse('请求中...')
        if (method === 'GET') {
            network.get(url).then(showResponse).catch(showError)
            return
        }

        if (method === 'POST') {
            let bodyJsonObject;
            try {
                bodyJsonObject = JSON.parse(bodyJson)
            } catch (e) {
                setResponse('请求体格式有误' + e)
                return
            }

            network.post(url, bodyJsonObject, { headers: { "Content-Type": "application/json" } })
                .then(showResponse)
                .catch(showError)
            return
        }
        if (method === 'DELETE') {
            network.delete(url)
                .then(showResponse)
                .catch(showError)
            return
        }
    }

    return (
        <Panel header={<p className="title">api测试</p>}>
            <BreadCrumb items={[{ active: true, title: 'api测试' }]} />

            <Grid className="grt-panel" fluid>
                <Row>
                    <Col md={8} mdOffset={8}>
                        <Form
                            fluid
                            model={ formValidatorModel }
                            ref={ formRef }
                            formValue={ formValue }
                            onChange={ formValue => setFormValue(formValue) }
                        >
                            <Form.Group controlId="method">
                                <Form.ControlLabel>请求类型</Form.ControlLabel>
                                <Form.Control
                                    name="method"
                                    defaultValue="GET"
                                    inline
                                    accepter={ RadioGroup }
                                >
                                    <Radio value="GET">Get</Radio>
                                    <Radio value="POST">Post</Radio>
                                    <Radio value="DELETE">Delete</Radio>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="url">
                                <Form.ControlLabel>请求地址</Form.ControlLabel>
                                <Form.Control name="url" />
                            </Form.Group>

                            {
                                formValue.method === 'POST' && (
                                    <Form.Group controlId="bodyType">
                                        <Form.ControlLabel>请求体类型</Form.ControlLabel>
                                        <Form.Control
                                            name="bodyType"
                                            defaultValue="json"
                                            inline
                                            accepter={ RadioGroup }
                                        >
                                            <Radio value="json">json</Radio>
                                            <Radio value="file">file</Radio>
                                        </Form.Control>
                                    </Form.Group>
                                )
                            }

                            {
                                formValue.method === 'POST' && formValue.bodyType === 'json' && (
                                    <Form.Group controlId="bodyJson">
                                        <Form.ControlLabel>请求体</Form.ControlLabel>
                                        <Form.Control
                                            name="bodyJson"
                                            style={{ height: '150px' }}
                                            accepter={ Textarea }
                                        />
                                    </Form.Group>
                                )
                            }

                            <Form.Group>
                                <Form.ControlLabel>响应内容</Form.ControlLabel>
                                <Form.Control
                                    name="response"
                                    value={ response }
                                    readOnly
                                    style={{ height: '300px' }}
                                    accepter={ Textarea }
                                />
                            </Form.Group>

                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={ submit }>提交</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Grid>
        </Panel>
    );
}

export default ApiTest