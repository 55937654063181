import { useState } from 'react'
import {
    Button,
    Divider,
    Input,
    Stack,
} from 'rsuite';

import CodeSelect from '../../../components/CodeView/CodeSelect'

export const initSearchParameter = { likeCodeDetailKey: '', likeCodeDetailName: '', eqState: '' }

const CodeDetailSearch = props => {

    const { onSearch } = props
    const [ searchParameter, setSearchParameter ] = useState(initSearchParameter)

    const handleChangeSearchParameter = (key, value) => setSearchParameter({ ...searchParameter, [key]: value })
    const handleSearch = () => onSearch?.(searchParameter)

    return (
        <Stack
            className="grt-panel"
            justifyContent="space-between"
            spacing={10}
        >
            <Stack
                className="grt-stack"
                divider={<Divider vertical />}
            >
                <Stack spacing={10}>
                    代码明细key：
                    <Input
                        value={ searchParameter.likeCodeDetailKey }
                        onChange={ value => handleChangeSearchParameter('likeCodeDetailKey', value) }
                    />
                </Stack>

                <Stack spacing={10}>
                    代码明细名称：
                    <Input
                        value={ searchParameter.likeCodeDetailName }
                        onChange={ value => handleChangeSearchParameter('likeCodeDetailName', value) }
                    />
                </Stack>

                <Stack spacing={10}>
                    代码明细状态：
                    <CodeSelect
                        codeKey='STATE'
                        searchable={false}
                        value={ searchParameter.eqState }
                        onChange={value => handleChangeSearchParameter('eqState', value || '') }
                    />
                </Stack>

                <Stack spacing={10}>
                    <Button
                        appearance="primary"
                        onClick={ handleSearch }
                    >
                        搜索
                    </Button>
                </Stack>                
            </Stack>
        </Stack>
    );
}

export default CodeDetailSearch